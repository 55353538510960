const skills = [
    {
      id: 1,
      src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg",
      name: "React",
      color: "shadow-blue-600",
    },
    {
      id: 2,
      src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg",
      name: "JavaScript",
      color: "shadow-yellow-500",
    },
    {
      id: 3,
      src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg",
      name: "HTML",
      color: "shadow-orange-500",
    },
    {
      id: 4,
      src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-original.svg",
      name: "Python",
      color: "shadow-yellow-500",
    },
    {
      id: 5,
      src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/anaconda/anaconda-original.svg",
      name: "Anaconda",
      color: "shadow-green-500",
    },
    {
      id: 6,
      src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/csharp/csharp-original.svg",
      name: "C#",
      color: "shadow-purple-500",
    },
    {
      id: 7,
      src: "https://upload.wikimedia.org/wikipedia/commons/9/93/Amazon_Web_Services_Logo.svg",
      name: "AWS",
      color: "shadow-orange-500",
    },
    {
      id: 8,
      src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/terraform/terraform-original.svg",
      name: "Terraform",
      color: "shadow-purple-500",
    },
    {
      id: 9,
      src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/azure/azure-original.svg",
      name: "Azure",
      color: "shadow-blue-500",
    },
    {
      id: 10,
      src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/docker/docker-original.svg",
      name: "Docker",
      color: "shadow-blue-600",
    },
    {
      id: 11,
      src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/firebase/firebase-plain.svg",
      name: "Firebase",
      color: "shadow-yellow-500",
    },
    {
      id: 12,
      src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original.svg",
      name: "Node.js",
      color: "shadow-green-500",
    },
    {
      id: 13,
      src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/vscode/vscode-original.svg",
      name: "VS Code",
      color: "shadow-blue-500",
    },
    {
      id: 14,
      src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/figma/figma-original.svg",
      name: "Figma",
      color: "shadow-pink-500",
    },
    {
      id: 15,
      src: "https://www.vectorlogo.zone/logos/tailwindcss/tailwindcss-icon.svg",
      name: "Tailwind",
      color: "shadow-sky-400",
    },
    {
      id: 16,
      src: "https://www.vectorlogo.zone/logos/unity3d/unity3d-icon.svg",
      name: "Unity",
      color: "shadow-gray-700",
    },
  ];

  export {skills};