import React, { useState } from 'react';
import Modal from './Modal';

const Card = ({ item }) => {
    return (
        <div>
            <div
                className="shadow-md shadow-gray-600 rounded-lg relative group overflow-hidden cursor-pointer"
            >
                <img
                    src={item.src}
                    alt="Card Image"
                    className="w-full h-80 object-cover rounded-md duration-200 group-hover:blur-sm"
                />
                <div className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 duration-200 text-center">
                    <p className="text-xl md:text-2xl lg:text-3xl text-white font-bold mb-2">{item.name}</p>
                    <p className="text-sm md:text-md lg:text-lg text-white">{item.position}</p>
                </div>
            </div>
        </div>
    );
};

export default Card;
