import { React, useState } from "react";
import { NewCard } from "./Card";
import { Article } from "./Article";
import { projects } from "../data/projects";
import Modal from "./Modal";

const Projects = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const openModal = (project) => {
    setSelectedProject(project);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedProject(null);
  };

  return (
      <div className="relative pb-64 md:pb-16 bg-black" name="projects">
        <div className="px-6 pt-16 mx-auto space-y-8 max-w-7xl lg:px-8 md:space-y-12 md:pt-24 lg:pt-32">
          <div className="max-w-2xl mx-auto lg:mx-0">
            <h2 className="text-4xl text-white font-bold border-b-4 border-gray-500  inline">
              Personal Projects
            </h2>
            <p className="mt-4 text-zinc-400">
              Click on a card for more information. 
            </p>
          </div>
  
          <div className="grid grid-cols-1 gap-4 mx-auto lg:mx-0 md:grid-cols-3 z-10">
            {projects.map((project) => (
              <NewCard key={project.name} >
                <Article project={project} setModalOpen={openModal}/>
              </NewCard>
            ))}
          </div>
        </div>
        {isModalOpen && selectedProject && (
          <Modal
            isOpen={isModalOpen}
            onClose={closeModal}
            project={selectedProject}
          />
        )}
      </div>
    );
  };
export default Projects;