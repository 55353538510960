import About from "./components/About";
import Education from "./components/Education";
import Home from "./components/Home";
import NavBar from "./components/NavBar";
import IndustryExperience from "./components/IndustryExperience";
import { NewCard } from "./components/Card";
import Projects from "./components/Projects";
import Skills from "./components/Skills";
import Modal from "./components/Modal";

function App() {
  return (
    <div className="App">
      <NavBar/>
      <Home/>
      <IndustryExperience/>
      <Projects/>
      <Skills/>
      <Education/>
      <Modal></Modal>
    </div>
  );
}

export default App;
