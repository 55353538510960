import Hudl from "../assets/images/HudlSite.png";
import Nimbus from "../assets/images/NimbusDrone.jpg";
import Sandhills from "../assets/images/SandhillsSite.png";
import NIYOU from "../assets/images/NIYOUSite.png";
import Raikes from "../assets/images/RaikesSite.png";

const Experiences = [
    {
      id: 1,
      title: "HUDL Sports",
      role: "Software Engineering intern",
      image: Hudl,
      timeframe: "Summer 2024",
      links: [{ title: "Hudl", url: "https://hudl.com" }, {title: "Github", url : "https://github.com/hudl"}],
      description: [
        "Leveraged .Net/C# on a business operations product team to re-architect Hudl’s access management microservice for 160k+ sports teams.",
        "Utilized SQS to produce and consume ~46k access summaries per day, allowing Hudl hardware to automatically detect access permission changes without needing to constantly ensure access.",
        "Integrated a three-tiered microservice architecture to create unified provisioning for all internal Hudl data to be backed by a Salesforce entity ensuring resource provisioning had less than 5% drift.",
        "Implemented the POC for unified provisioning with Extra Storage Hours for teams while filtering SQS consumers to reduce queuing costs in this microservice by 95%.",
        "Ingested DocumentDB records into a Redshift relational database for non-technical employees to easily query, analyze, and generate reports.",
        "Led the architectural design for integrating Visual Compliance with Shopify and Salesforce to identify and flag high-risk clients, ensuring compliance with legal and regulatory requirements.",
      ],
      features: [
        "Entity backed data for access management.",
        "Extra Storage Hours"
      ],
        
      tools: [
        ".NET/C#",
        "React JS",
        "AWS SQS",
        "DocumentDB",
        "Redshift",
        "Docker",
      ],
    },
    {
      id: 2,
      title: "NIMBUS Lab",
      role: "Undergraduate Researcher",
      image: Nimbus,
      timeframe: "January 2023 - Present",
      links: [{ title: "NIMBUS", url: "https://nimbus.unl.edu" }, {title: "Github", url : "https://github.com/unl-nimbus-lab"}],
      description: [
        "Developed a containerized tool for UAV agents to monitor health, expected behaviors, and inter-agent communication, enhancing anomaly detection.",
        "Developed components of a UAV swarm project controller and GUI, including reading and writing critical parameters, scanning for vulnerabilities, and generating Docker compose files.",
        "Authored an AIAA research paper on innovative pre-flight anomaly detection and in-flight health assessment techniques, contributing new methodologies to the UAV research community."
      ],
      features: [
        "Containerized PHM Tool",
        "AIAA Research Paper"
      ],
      tools: [
        "Javascript",
        "React",
        "Python",
        "Docker",
      ],
    },
    {
      id: 3,
      title: "NIYOU",
      role: "Chief Information Officer",
      image: NIYOU,
      timeframe: "July 2023 - Present",
      links: [{ title: "NIYOU", url: "https://ni-you.com" }, {title: "Github", url : "https://github.com/NI-YOU"}],
      description: [
        "Developed an engaging landing page to drive customer acquisition for a Creighton-based startup focusing on college NIL deals.",
        "Leading development on a private-facing client portal providing athletes and sponsors with a streamlined deal flow process.",
        "Collaborated with co-founders to secure over $50,000 in NIL deals for contracted athletes."
      ],
      features: [
        "WebApp",
        "Business strategy"
      ],
      tools: [
        "React",
        "Firebase",
      ],
    },
    {
      id: 4,
      title: "Sandhills Global",
      role: "Software Developer Intern",
      image: Sandhills,
      timeframe: "Summer 2023",
      links: [{ title: "Sandhills", url: "https://sandhills.com" }],
      description: [
        "Developed key components for Sandhills’ domestic websites working with .NET/C#/React, contributing to an overall effort to increase user experience.",
        "Audited and updated trade sites to meet ADA compliance standards, achieving 95% compliance across all audited sites.",
        "Spearheaded a single site’s SEO optimization project, resulting in an increase in organic search traffic through enhancements to mockups, metadata, and backlink quality.",
        "Implemented an advertising inquiry form, streamlining the process and improving sales representative notification efficiency."
      ],
      features: [
        "ADA compliance project",
        "SEO optimization project"
      ],
      tools: [
        ".NET/C#",
        "React",
        "MVC",
        "Azure DevOps",
      ],
    },
    {
      id: 5,
      title: "Raikes School",
      role: "Web Developer and Social Media Manager",
      image: Raikes,
      timeframe: "Febuary 2023 - Present",
      links: [{ title: "Raikes School", url: "https://raikes.unl.edu" }, {title: "Github", url : "https://github.com/RaikesSchoolDS"}],
      description: [
        "Led social media management efforts, achieving a 17% increase in followers through strategic content creation and engagement initiatives.",
        "Assisted with website updates, including blog content management, converting archived PDFs into accessible webpages to improve user experience, and website requests from faculty and staff."
      ],
      features: [
        "Social media fundrasing campaign.",
        "Website blog post features."
      ],
      tools: [
        "HTML/CSS",
        "Content creation",
      ],
    },
  ];

export {Experiences};