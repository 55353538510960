import React from "react";
import Card from "./ImageCard";
import UNL from "../assets/images/Raikes.jpg"
import MM from "../assets/images/MountMichael.jpg"

const Education = () => {

    const EducationItems = [
        { id: 1,  name: "University of Nebraska-Lincoln", src: UNL},
        { id: 2,  name: "Mount Michael HS", src: MM},

    ]

    return (
        <div name="education" className="bg-gradient-to-b from-black to-gray-700 w-full text-white md:h-screen">
            <div className="max-w-screen-xl p-4 mx-auto flex flex-col justify-center w-full h-full">
                <div className="pb-20">
                    <p className="text-4xl font-bold inline border-b-4 border-gray-50">Education</p>
                </div>
                <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-8 px-12 sm:px-0">
                    {EducationItems.map(item => (
                        <Card item={item}/>
                    ))}
                </div>
            </div>
        </div>
    )
}
export default Education;