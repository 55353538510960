import React from "react";
import { skills } from "../data/skills";

const Skills = () => {
      return (
        <div className="relative pb-64 md:pb-16 bg-black" name="projects">
          <div className="px-6 pt-16 mx-auto space-y-8 max-w-7xl lg:px-8 md:space-y-12 md:pt-24 lg:pt-32">
            <div
              name="skills"
              className="bg-black w-full h-screen"
            >
              <div className="max-w-screen-xl mx-auto p-4 flex flex-col justify-center w-full h-full text-white ">
                <div>
                  <p className="text-4xl font-bold border-b-4 border-gray-500 p-2 inline">
                    Skills
                  </p>
                </div>
        
                <div className="w-full grid grid-cols-2 sm:grid-cols-3 gap-8 text-center py-8 px-12 sm:px-0">
                  {skills.map((skill) => (
                    <div
                      key={skill.id}
                      className={`shadow-md hover:scale-105 duration-500 py-2 rounded-lg ${skill.color}`}
                    >
                      <img src={skill.src} alt="" className="w-20 mx-auto" />
                      <p className="mt-4">{skill.name}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    };

export default Skills;