import { EyeIcon, MarkGithubIcon, StarIcon, LockIcon } from "@primer/octicons-react";
import Modal from "./Modal";
import { useState } from "react";

export const Article = ({ project, setModalOpen }) => {
  const githubLink = project.links?.find(link => link.title.toLowerCase() === "github");

    return (
      <article className="p-4 md:p-8">
        <div className="flex justify-between gap-2 items-center">
          <span className="text-xs text-zinc-200">
             <p> {project.timeframe} </p>
          </span>
          <span className="text-zinc-500 text-xs flex items-center gap-1">
            <span title="Total stars.">
            {githubLink && (
                <div className="inline-flex items-center space-x-1">
                  <a
                      href={githubLink.url === "private" ? "#" : githubLink.url}
                      target="_blank"
                      className={`inline-block text-white rounded px-3 py-1 text-sm transition text-center flex items-center gap-2 ${githubLink.url === "private" ? "cursor-not-allowed opacity-50" : "hover:bg-blue-800"}`}
                      onClick={(e) => githubLink.url === "private" && e.preventDefault()}
                  >
                      <MarkGithubIcon className="w-4 h-4" />
                      {githubLink.url === "private" && (
                      <span className="inline-block text-gray-500">
                      <LockIcon className="w-4 h-4" />
                      </span>
                  )}
                  </a>
                </div>
            )}
            </span>
          </span>
        </div>
  
        <a legacyBehavior>
          <h2 className="text-xl font-medium lg:text-3xl text-zinc-200 cursor-pointer" title={`Click to view the ${project.homepage ? 'app' : 'repo'}.`} onClick={() => setModalOpen(project)}>
            <span className="bg-gradient-to-r from-green-400 to-blue-500 hover:from-green-800 hover:to-blue-800 text-transparent bg-clip-text">
              {project.title}
            </span>
          </h2>
        </a>
        <p className="mt-4 text-sm text-zinc-400">
          {project.role}
        </p>
        <div className="flex justify-between gap-2 items-center mt-2 border-t-2 border-gray-700 border-opacity-50">
          <span className="text-zinc-500 text-xs flex items-center gap-1">
          </span>
        </div>
      </article>
    );
  };
  