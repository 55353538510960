import ChapterCheck from "../assets/images/ChapterCheckSite.png"
import Catan from "../assets/images/CatanSite.png"
import Pipeline from "../assets/images/Pipeline.png"
import SpaceShooter from "../assets/images/SpaceShooterSite.png"
import SoccerXG from "../assets/images/SoccerXG.png"
import Practitioner from "../assets/images/AWSDeveloper.png"
import Developer from "../assets/images/AWSPractitioner.png"
import FAA from "../assets/images/FAA.png"
import Portfolio from '../assets/images/PersonalPortfolio.png'
import MMI from '../assets/images/MMIPaper.png'
import PHM from '../assets/images/NIMBUSPaper.png'

const projects = [
    {
      id: 1,
      title: "Chapter Check",
      role: "SaaS, API, Mobile Project",
      image: ChapterCheck,
      fitType: "fill",
      timeframe: "December 2023 - Present",
      links: [{ title: "Chapter Check Website", url: "https://mychaptercheck.com" }, { title: "Github", url: "private" }],
      description: [
        "Developed a SaaS platform for Greek organizations, facilitating member management and attendance tracking for chapter meetings, philanthropies, and study hours through geo-location features.",
        "Engineered a mobile application using React Native and Expo, delivering a user-friendly experience for members. Also created a web application for moderators, enhancing administrative control and oversight, and reached over 150 users.",
        "Architected and implemented server infrastructure using cloud services, Terraform for infrastructure as code, API integrations, and JWT authentication for secure user access."
      ],
      features: [
        "Member management and attendance tracking",
        "Mobile and web applications for user-friendly experience"
      ],
      tools: [
        "JavaScript",
        "React",
        "Express",
        "AWS",
        "Tailwind",
        "Terraform",
        "React Native",
        "Expo",
        "Docker"
      ],
    },
    {
      id: 2,
      title: "Catan",
      role: "Full-Stack WebApp Project",
      image: Catan,
      fitType: "fill",
      timeframe: "Spring 2024",
      links: [{ title: "Catan", url: "https://catan-4dea2.web.app/" }, { title: "Github", url: "https://github.com/landenf/Settlers-of-Catan" }],
      description: [
        "Led the development of an online multiplayer adaptation of the board game Settlers of Catan using React, Express, and hosted on AWS EC2.",
        "Implemented real-time gameplay features, user authentication, and accessible user interfaces."
      ],
      features: [
        "Online multiplayer adaptation of Settlers of Catan",
        "Real-time gameplay and user authentication"
      ],
      tools: [
        "React",
        "Express",
        "AWS",
        "WebSockets",
        "Firebase",
        "HTML/CSS",
      ],
    },
    {
      id: 3,
      title: "CI/CD Pipeline POC",
      role: "Proof of Concept",
      image: Pipeline,
      fitType: "fill",
      timeframe: "Spring 2023",
      links: [{ title: "Github", url: "https://github.com/landenf/CI-CD-Model" }],
      description: [
        "Architected and implemented a comprehensive CI/CD pipeline as a proof-of-concept, showcasing the possibility to seamlessly integrate DevOps practices into teams.",
        "Automated the software development lifecycle, encompassing developer implementation, code review (Git), code build, testing, and deployment (AWS), and final staging stakeholder approval."
      ],
      features: [
        "Comprehensive CI/CD pipeline implementation",
        "Seamless integration of DevOps practices"
      ],
      tools: [
        "Python",
        "AWS"
      ],
    },
    {
      id: 4,
      title: "Machine Learning Approach for Predicting Outcomes of Premier League Soccer Matches",
      role: "Research Paper",
      image: SoccerXG,
      fitType: "fill",
      timeframe: "Spring 2024",
      links: [ {title: "View Paper", url: "https://drive.google.com/file/d/1mHwmy36THagb9surU00cf2duT0Q1c7Gs/view?usp=sharing"}, { title: "Github", url: "https://github.com/landenf/Expected-Soccer-Goals-ML" }],
      description: [
        "This project analyzes soccer game data to predict match outcomes based on teams' historical performance in terms of expected goals (xG) and actual goals.",
        "It utilizes machine learning techniques, specifically K-Nearest Neighbors (KNN) and Rolling Forecasting, to provide predictions and insights."
      ],
      features: [
        "Machine learning soccer prediction",
        "AIAA conference presentation"
      ],
      tools: [
        "Python",
        "SkLearn"
      ],
    },
    {
      id: 5,
      title: "Integrated Prognostics and Health Monitoring Tool Aboard UAS Swarm Agents",
      role: " AIAA Research Paper",
      image: PHM, // No image available
      fitType: "fill",
      timeframe: "November 2023 - Present",
      links: [{title: "View Paper", url: "https://drive.google.com/file/d/1MO7VDwXW8zQBhl6yLrhDDYFmg0eGwH5V/view?usp=sharing"}],
      description: [
        "Authored an AIAA research paper (in review) on innovative pre-flight anomaly detection and in-flight health assessment techniques, contributing new methodologies to the UAV research community.",
        "As part of this research, developed a containerized tool for UAV agents to monitor health, expected behaviors, and inter-agent communication, enhancing anomaly detection within automous swarms.",
      ],
      features: [
        "UAV swarm communication research",
        "Anomaly detection techniques"
      ],
      tools: [
        "Python",
        "Docker",
        "Robotic Operating System",
      ],
    },
    {
        id: 6,
        title: "Marcus and Millchap Equity Report",
        role: "Resesarch Paper",
        image: MMI,
        fitType: "contain",
        timeframe: "Fall 2022",
        links: [{ title: "Equity Report", url: "https://drive.google.com/file/d/1n0g9Act4mSZ-oqsMNUpFsyy60cCO_5Mj/view?usp=sharing" }],
        description: [
            "Conducted in-depth analysis of publicly traded companies, using a variety of financial and market data sources.",
            "Created financial reports to evaluate the performance and potential of individual stocks, as well as the entire 500k portfolio.",
            "Placed first in semesterly stock pitch competition alongside equity group researching Marcus and Millichap Inc.",
        ],
        features: [
            "Financial Analysis",
            "Pitch Competition"
        ],
    },
    {
      id: 7,
      title: "Space Shooter",
      role: "3D Video Game",
      image: SpaceShooter,
      fitType: "fill",
      timeframe: "2019-2020",
      links: [{ title: "Play Online", url: "#" }, { title: "Github", url: "https://github.com/landenf/SpaceShooter2000" }],
      description: [
        "Developed a FPS 'endless runner' video game using C# and Unity Engine."
      ],
      features: [
        "Space shooter game development"
      ],
      tools: [
        "C#",
        "Unity Engine"
      ],
    },
    {
      id: 8,
      title: "AWS Certified Practitioner",
      role: "Level 1 Certification",
      image: Developer,
      fitType: "contain",
      timeframe: "2024",
      links: [{ title: "AWS Certified Practitioner", url: "https://aws.amazon.com/certification/certified-cloud-practitioner/?ch=sec&sec=rmg&d=1" }],
      description: [
        "Certified as an AWS Certified Cloud Practitioner, demonstrating foundational knowledge of AWS services, cloud concepts, security, and compliance.",
        "Proficient in navigating the AWS Management Console, understanding AWS pricing and support models, and identifying AWS services for common use cases."
      ],
      features: [
        "AWS foundational knowledge",
        "Cloud concepts and security proficiency"
      ],
      tools: [
        "AWS"
      ],
    },
    {
      id: 9,
      title: "AWS Certified Developer - Associate",
      role: "Level 2 Certification",
      image: Practitioner,
      fitType: "contain",
      timeframe: "2024",
      links: [{ title: "AWS Certified Developer - Associate", url: "https://aws.amazon.com/certification/certified-developer-associate/?ch=sec&sec=rmg&d=1" }],
      description: [
        "Certified after successfully passing the AWS Certified Developer - Associate exam, demonstrating proficiency in developing, deploying, and debugging cloud applications on AWS.",
        "Skills include using AWS SDKs, managing AWS services, implementing security best practices, and optimizing cloud-based solutions for scalability and performance."
      ],
      features: [
        "AWS cloud application development",
        "Security and performance optimization"
      ],
      tools: [
        "AWS"
      ],
    },
    {
      id: 10,
      title: "FAA Certified Part 107",
      role: "Certification",
      image: FAA,
      fitType: "contain",
      timeframe: "2024",
      links: [{ title: "FAA Part 107 Certification", url: "https://www.faa.gov/uas/commercial_operators/become_a_drone_pilot" }],
      description: [
        "Certified by the Federal Aviation Administration (FAA) as an Unmanned Aircraft Pilot under Part 107 regulations.",
        "Demonstrated knowledge of airspace regulations, safety procedures, and operational requirements for commercial drone operations."
      ],
      features: [
        "FAA Part 107 certification",
        "Commercial drone operations knowledge"
      ],
    },
    {
    id: 11,
    title: "Personal Portfolio",
    role: "Personal Project",
    image: Portfolio,
    fitType: "contain",
    timeframe: "Summer 2024",
    links: [{ title: "Github", url: "https://github.com/landenf/Personal-Portfolio"}],
    description: [
        "Developed a personal website to display my projects, achievements, and experiences.",
    ],
    tools: [
        "React JS",
        "TailwindCSS"
    ],
    },
  ];

export {projects};