import React from 'react';
import { MarkGithubIcon, StarIcon, LockIcon } from "@primer/octicons-react";

const Modal = ({ isOpen, onClose, project }) => {
  if (!isOpen) return null;

  const githubLink = project.links?.find(link => link.title.toLowerCase() === "github");
  const objectFitStyle = project.fitType === 'fill' ? 'cover' : 'contain';

  return (
    <div id="project-modal" className="hs-overlay fixed inset-0 z-50 flex items-center justify-center">
      <div onClick={onClose} className="absolute inset-0 bg-black opacity-80"></div>
      <div className="relative w-3/4 max-w-4xl h-3/4 bg-white dark:bg-neutral-800 text-black dark:text-white rounded-lg shadow-lg overflow-y-auto">
        <button onClick={onClose} className="absolute top-4 right-4 text-2xl font-bold">
          &times;
        </button>
        <div className="w-full h-2/4 flex justify-center items-center bg-gray-200 dark:bg-gray-700">
          {project.image ? (
            <img
              className="w-full h-full object-cover rounded-t-lg"
              src={project.image}
              alt={project.title}
              style={{ objectFit: objectFitStyle, objectPosition: 'center' }}
            />
          ) : (
            <div className="w-full h-full flex items-center justify-center bg-gray-300 dark:bg-gray-700 rounded-t-lg">
              <span className="text-gray-700">No Image Available</span>
            </div>
          )}
        </div>
        <div className="p-8 text-center">
            <h2 className="text-3xl font-bold mb-4">{project.title}</h2>
            <p className="text-lg font-medium mb-4">{project.timeframe}</p>
            <div className="mb-4 flex justify-center space-x-2">
            {project.links
                ?.filter(link => link.title.toLowerCase() !== "github")
                .map((link, index) => (
                <a
                    key={index}
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-block bg-yellow-500 text-black rounded px-3 py-1 text-sm hover:bg-yellow-600 transition text-center"
                >
                    {link.title}
                </a>
                ))}
             {githubLink && (
                <div className="inline-flex items-center space-x-1">
                    <a
                        href={githubLink.url === "private" ? "#" : githubLink.url}
                        className={`inline-block bg-blue-600 text-white rounded px-3 py-1 text-sm transition text-center flex items-center gap-2 ${githubLink.url === "private" ? "cursor-not-allowed opacity-50" : "hover:bg-blue-700"}`}
                        onClick={(e) => githubLink.url === "private" && e.preventDefault()}
                    >
                        <MarkGithubIcon className="w-4 h-4" />
                        {githubLink.url === "private" && (
                            <span className="inline-block text-gray-500">
                            <LockIcon className="w-4 h-4" />
                            </span>
                        )}
                    </a>
                </div>
            )}
            </div>
          <div>
            <h3 className="text-2xl font-bold mb-4">Overview</h3>
            <ul className="list-none text-lg mb-4 text-left space-y-2">
              {project.description.map((point, index) => (
                <li key={index} className="flex items-start">
                  <StarIcon className="w-5 h-5 text-yellow-500 mr-2 mt-1" />
                  <span>{point}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="px-8">
          {project.features?.length > 0 && (
            <div>
              <h3 className="text-2xl font-bold mb-2">Features</h3>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                {project.features.map((feature, index) => (
                  <div key={index} className="bg-gray-200 dark:bg-gray-700 p-4 rounded">
                    {feature}
                  </div>
                ))}
              </div>
            </div>
          )}
          {project.tools?.length > 0 && (
            <div className="mt-4">
              <h3 className="text-2xl font-bold mb-2">Tools Used</h3>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                {project.tools.map((tool, index) => (
                  <div key={index} className="bg-gray-200 dark:bg-gray-700 p-4 rounded">
                    {tool}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
