import React from "react";
import Landen from "../assets/images/LandenFogle.jpg";
import { FaLinkedin, FaInstagram, FaGithub, FaEnvelope } from "react-icons/fa";

const Home = () => {
  return (
    <div
      name="home"
      className="h-screen w-full bg-gradient-to-b pt-32 md:pt-1 from-gray-600 via-black to-black"
    >
      <div className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
        <div className="flex flex-col justify-center h-full">
          <h2 className="text-4xl sm:text-7xl font-bold text-white">
            I am a Software Developer
          </h2>
          <p className="text-gray-500 py-4 max-w-md">
            Currently, I am a Computer Science student at the University of Nebraska-Lincoln. I am actively seeking professional opportunities to gain more experience in the realm of technology, especially with a focus in networking, software engineering, and DevOps positions.
          </p>
          <p className="block md:hidden text-gray-500 py-4 max-w-md">
            My portfolio is best viewed on a laptops/desktops.
          </p>
          <div className="flex items-center space-x-4 mt-2">
            <a href="https://drive.google.com/file/d/1NixVx5s6-oDlzVPWc14QZqyw_daAjWn5/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 cursor-pointer">
              View CV
            </a>
            <a href="https://www.linkedin.com/in/landen-fogle/" target="_blank" rel="noopener noreferrer" className="text-white hover:text-cyan-500">
              <FaLinkedin size={30} />
            </a>
            <a href="https://www.instagram.com/landen_fogle" target="_blank" rel="noopener noreferrer" className="text-white hover:text-cyan-500">
              <FaInstagram size={30} />
            </a>
            <a href="https://github.com/landenf" target="_blank" rel="noopener noreferrer" className="text-white hover:text-cyan-500">
              <FaGithub size={30} />
            </a>
            <a href="mailto:landenfogle@gmail.com" className="text-white hover:text-cyan-500">
              <FaEnvelope size={30} />
            </a>
            
          </div>
        </div>

        <div>
          <img
            src={Landen}
            alt="my profile"
            className="rounded-full mx-auto w-full max-w-xs md:max-w-sm lg:max-w-md"
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
